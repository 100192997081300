import React, { useEffect } from "react";

const Biblio_davis = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    });
    
    return (
        <div>
            <div className="individual-words-hero-image-container">
                <img className="individual-words-hero-image-horizontal" src={'https://lorraineshemeshsiteimgs.s3.us-east-2.amazonaws.com/paint-ww/echo.jpg'} alt="Echo"/>
            </div>
            <div className="individual-words-paragraph-container">
                <h4 className="individual-words-title"><b>LORRAINE SHEMESH - ART INTERVIEW ONLINE MAGAZINE</b></h4>
                <h4 className="individual-words-paragraph">American artist Lorraine Shemesh investigates and abstracts the human form. She has developed a unique type of contemporary realism that marries figure-based painting with abstract expressionist concerns. Shemesh has worked in varied media, including painting, drawing, fiber, paper, and clay. She studied ballet as a child before discovering painting. This focus informs even her latest body of work, Intersections, which depicts the intertwining movements of two dancers in vivid black and white costumes. The dancers are anonymous and suspended in space, the lines and curves of their forms suggestive of motion and meaning. Shemesh's most recognizable works are her large-scale pool paintings, featuring the play of refracted light on swimmers' bodies submerged underwater. These scenes contain images of everyday life made foreign and spectacular by their treatment.</h4>

                <img className="individual-words-image-horizontal" src={'https://lorraineshemeshsiteimgs.s3.us-east-2.amazonaws.com/paint-int/lock_03_colorCorrected.jpg'} alt="Lock" />
                
                <h4 className="individual-words-paragraph">Shemesh was inspired by Chaim Soutine, Richard Diebenkorn and Edward Hopper. She received her B.F.A. in Painting from Boston University in 1971, then spent a year in Rome with the Tyler School of Art's abroad program, completing her M.F.A. in Painting in 1973 at Tyler School of Art in Philadelphia. After graduating, Shemesh immediately began exhibiting her work in solo and group shows, as well as accepting teaching positions at Rhode Island School of Design and later at Amherst College. In 1981, she joined the Allan Stone Gallery in New York. She has exhibited her work internationally at the San Francisco Museum of Modern Art, The National Academy of Design Museum in New York, Musee de Carouge in Carouge, Switzerland, Galerij Marc Van Meensel in Zelem-Halen, New Belgium, and the Gallery of Landesverband Bildender Kunstler, in Kiel, Germany, amongst others. Shemesh currently lives and works in New York City and is represented by the Allan Stone Gallery.</h4>
                <h4 className="individual-words-paragraph"><b>Art Interview:</b> Lorraine, where are you from?</h4>
                <h4 className="individual-words-paragraph"><b>Lorraine Shemesh:  </b>I was born in Jersey City and I grew up on the Palisades in New Jersey, overlooking the Hudson River.</h4>

                <img className="individual-words-image-vertical" src={'https://lorraineshemeshsiteimgs.s3.us-east-2.amazonaws.com/paint-int/zipper_4_colorCorrected_c.jpg'} alt="Zipper" />
                
                <h4 className="individual-words-paragraph"><b>Art Interview:</b> Did your family have an influence on your decision to become an artist?</h4>
                <h4 className="individual-words-paragraph"><b>Lorraine Shemesh: </b>My parents both valued the arts, especially the visual arts. My father was a food salesman, who drew with me regularly, and my mother, a medical technician, who later became the Program Coordinator in the Department of Pathology at Columbia Presbyterian Medical Center, also sewed and enjoyed making things by hand. When I was a child, my parents managed to send me to dance class, so I studied classical ballet locally for about seven years. At a certain point, the teacher approached my parents about sending me to a more rigorous program in New York City, but my parents didn't feel that would be possible. They decided instead to supply me with a set of oil paints. I was ten years old at that time, and I remember using the whole box of paint in just a few days. From then on, that's pretty much what I did in my spare time. I don't think one chooses to be an artist. It is simply who you are and how you navigate in the world from the very beginning.</h4>
                <h4 className="individual-words-paragraph">When applying to college, I was accepted into the Rhode lsland School of Design, but I decided to go to the School of Visual Arts at Boston University, where the program included musical arts and theater. The visual arts training there was intense and formidable.</h4>
                <h4 className="individual-words-paragraph"><b>Art Interview: </b>Would you describe your education at the B.F.A. level as very academic?</h4>
                <h4 className="individual-words-paragraph"><b>Lorraine Shemesh:</b> It was a very classical approach. We studied anatomy and the skeletal system and learned to draw from memory, the origin, insertion and action of every surfacing muscle in the human body. Working directly from life was the basis for all the classes in painting, drawing, and sculpture. It gave me a strong foundation that allowed me to fully express my ideas later on. As students, we traveled from Boston to New York City regularly to attend meetings of the Figurative Alliance, and its members often held heated debates. lt was the first time I had witnessed foot-stomping arguments among artists and the factional nature of aesthetic stances in the art world. I received several scholarships and prizes while I was there, and I was inspired by painters like Richard Yarde, David Ratner, James Weeks, and Reed Kay who taught in the program. The visiting artist program included artists like Paul Georges.</h4>
                <h4 className="individual-words-paragraph">During the summer after my sophomore year, I won a fellowship to study at the Tanglewood Institute in Lenox, Massachusetts with the painter Philip Pearlstein. When it came time to graduate, since I had never really traveled, I applied to the program the Tyler School of Art had in Rome. I won a tuition scholarship and later a fellowship in painting that allowed me to complete my graduate work when I returned to Philadelphia.</h4>
                <h4 className="individual-words-paragraph"><b>Art Interview:</b> What was Philip Pearlstein like?</h4>
                <h4 className="individual-words-paragraph"><b>Lorraine Shemesh:</b> He was the first teacher that I had who was really active in the New York art world. He made me think about things in a difierent way and forced me to step outside of the box. I learned from him how to really use the figure as a way of designing and composing a picture plane in a very abstract way. It wasn't about academic concerns; it was really about making abstract shapes and locking them together in a personal way. We got to see him work and it was eye opening to see someone work in a different way.</h4>
                <h4 className="individual-words-paragraph"><b>Art Interview: </b>How did that experience compare to studying in Rome?</h4>
                <h4 className="individual-words-paragraph"><b>Lorraine Shemesh:</b> The year in Italy really opened my eyes to the world. I was overwhelmed and over my head in Baroque and Renaissance art. That time, on a certain level, made me understand that the imagery I was most interested in, concerned American structures. When I returned to the U.S.A., after that year, I began digging up every Edward Hopper image I could find. I was looking for very simple kinds of shapes. Although, I remember that during my year in Italy, Balthus (Balthasar Klossowski) was in residence in Rome, at the French Academy. I loved his invented street scenes and the original manner in which he used space. The work I was drawn to most in Italy was the sculpture rather than the painting. Although I liked the drama of the Caravaggio's, it was really the sculpture that was most moving. I was always drawn to strong form, and Italy was certainly a country filled with that. The light was also pretty amazing.</h4>
                
                <img className="individual-words-image-vertical" src={'https://lorraineshemeshsiteimgs.s3.us-east-2.amazonaws.com/paint-bts/link.jpg'} alt="Zipper" />
                
                <h4 className="individual-words-paragraph"><b>Art Interview: </b>Did you have to hold a job while you were attending university?</h4>
                <h4 className="individual-words-paragraph"><b>Lorraine Shemesh:</b> I held jobs in the summers. Since I had received a fellowship, I had a stipend of money each month that allowed me to buy paint. I lived in a very tiny place, and I didn't do much except paint twelve to fifteen hours a day. During graduate school, I was pretty much in the studio all the time. When I got out I started applying for teaching jobs and I found myself at the College Arts Association Conference that was held in New York City that year. I managed to get the last interview on the sign-up sheet with the search committee from the Rhode Island School of Design. They were looking for an instructor in drawing that year. I was 24 years old, didn't have much experience, and over 800 people had applied for the job. So, I didn't think I stood a chance. The interview went well, and I soon learned that I was one of three finalists for the position. They flew me up to Providence, Rhode Island for the day for another interview, but then I didn't hear from them for over a month. Finally, I couldn't handle the suspense any longer, so I called the school and was at last told that I had gotten the job. That was a major moment in my life, because I knew then that I was going to be able to keep painting.</h4>
                
                <img className="individual-words-image-vertical" src={'https://lorraineshemeshsiteimgs.s3.us-east-2.amazonaws.com/paint-ww/ellipse_v3.jpg'} alt="Zipper" />
                
                <h4 className="individual-words-paragraph"><b>Art Interview: </b>Were there any professors that influenced or supported you at Tyler?</h4>
                <h4 className="individual-words-paragraph"><b>Lorraine Shemesh:</b> John Moore was actually the person that I went there to study with. He was doing cityscapes of the kinds of American cities I was familiar with. Also, Charles Schmidt, whose graphite drawings I greatly admired, was an influence.</h4>
                <h4 className="individual-words-paragraph"><b>Art Interview: </b>Were you attempting to emulate these role models?</h4>
                <h4 className="individual-words-paragraph"><b>Lorraine Shemesh:</b> Not literally. I liked Edward Hopper's work, and I was also interested in the work of Chaim Soutine, Wayne Thiebaud, and Richard Diebenkorn. I liked very juicy paint handling.</h4>
                <h4 className="individual-words-paragraph"><b>Art Interview: </b>How did your early work differ from what you're doing now?</h4>
                <h4 className="individual-words-paragraph"><b>Lorraine Shemesh:</b> I think the abstract base is more substantial now than it was in the beginning. lnitially, I was working completely from life. I would set up a specific circumstance. Now, I work more with a series of shapes, and although there are models at some point, much of it is made up. I use a lot of different things to get to the finished image. So, the process is deeper now.</h4>
                <h4 className="individual-words-paragraph">There are certain connecting threads to my earliest works: I always loved patterns on form, light, and the figure. Sometimes the pattem wrapping around the form is created by light striations, sometimes by surface patterns on fabric, and often by color shifts.</h4>
                <h4 className="individual-words-paragraph">I have to say, teaching at RISD really extended my education. The kinds of design problems that people dealt with were very inspiring.</h4>
                
                <img className="individual-words-image-vertical" src={'   https://lorraineshemeshsiteimgs.s3.us-east-2.amazonaws.com/drawings-pools21.jpg'} alt="Zipper" />
                
                <h4 className="individual-words-paragraph"><b>Art Interview: </b>Do you consider yourself a photorealist?</h4>
                <h4 className="individual-words-paragraph"><b>Lorraine Shemesh:</b> I use photographs as part of the process, but I don't really categorize myself in that way. I use photography as a means to something else. I work with live models and I do a lot of drawings. I am interested in a kind of specificity that precludes illustration and transcends photorealism both in content and painterly acumen.</h4>
                <h4 className="individual-words-paragraph"><b>Art Interview: </b>What do you believe the medium of paint, combined with realist depiction, brings to our understanding of an image?</h4>
                <h4 className="individual-words-paragraph"><b>Lorraine Shemesh:</b> The potential for visual magic, for me, lies in the union of the two. I'm interested in a lot of the concerns of the Abstract Expressionists. I like the spontaneous nature of a certain kind of mark making and the challenge of marrying that to the figure. Combining the two has the potential to fortify the painting. I try to balance intuition with preparation. Developing the form with various paint viscosities makes one see differently. You're transforming the object, so I think about it as a kind of synthesis.</h4>
                <h4 className="individual-words-paragraph"><b>Art Interview: </b>Do you break through the surface plane or act upon it?</h4>
                <h4 className="individual-words-paragraph"><b>Lorraine Shemesh:</b>  Well, sometimes a combination of the two. I had a solo show at the Butler Institute of American Art Museum in Youngstown, Ohio that was called Breaking the Surface. The title referenced not just a metaphorical relationship, but a physical one, as well.</h4>
                <h4 className="individual-words-paragraph"><b>Art Interview: </b>That being said, are you attempting to go beyond the technical issues of form, shape and color within your paintings?</h4>
                <h4 className="individual-words-paragraph"><b>Lorraine Shemesh:</b> Absolutely. When you choose to deal with the figure, you are dealing with a charged form; psychologically, physically, and certainly visually. There are certain conceptual issues that I'm confronting, like disjuncture and continuity. You know, how do you use the figure in today's world and make it relevant? How do you make it something vital, pulsing, and sensual?</h4>
                
                <h4 className="individual-words-paragraph"><b>Art Interview: </b>Can you describe your studio?</h4>
                <h4 className="individual-words-paragraph"><b>Lorraine Shemesh:</b> I work in a renovated old factory building in midtown Manhattan. It is a large loft space.</h4>
                <h4 className="individual-words-paragraph"><b>Art Interview: </b>Do you work in your studio every day?</h4>
                <h4 className="individual-words-paragraph"><b>Lorraine Shemesh:</b> Yes. I get up early, take a ballet class, return to the studio, and work about six or seven hours, have something to eat, and then work a second shift.</h4>
                <h4 className="individual-words-paragraph"><b>Art Interview: </b>Do you have assistants working with you?</h4>
                <h4 className="individual-words-paragraph"><b>Lorraine Shemesh:</b> No. I hire people to build the stretchers, and I hire models, but I basically do the work of making the paintings myself. It's challenging certainly, but it's truly what I like to do. Pretty much every decision that I've made in my life has been based on the desire to make my own work.</h4>
                <h4 className="individual-words-paragraph"><b>Art Interview: </b>Have you experienced fluctuations in your productivity through the years?</h4>
                <h4 className="individual-words-paragraph"><b>Lorraine Shemesh:</b>  I'm a worker and I've done a lot of different kinds of things. I work slowly but steadily. Sometimes life impacts the work that you do, but I've pretty much managed to work through difficult times.</h4>
                
                <img className="individual-words-image-vertical" src={'https://lorraineshemeshsiteimgs.s3.us-east-2.amazonaws.com/lorraine-painting-interview.jpg'} alt="Zipper" />
                
                <h4 className="individual-words-paragraph"><b>Art Interview: </b>How many works do you currently average in a year?</h4>
                <h4 className="individual-words-paragraph"><b>Lorraine Shemesh:</b>  Most of the paintings are very large, some of them are seven feet high, and they take months to do. If I'm working smaller or in other mediums, I can do many pieces in just weeks. It depends on what I'm doing.</h4>
                <h4 className="individual-words-paragraph"><b>Art Interview: </b>Why are you attracted to oversize works?</h4>
                <h4 className="individual-words-paragraph"><b>Lorraine Shemesh:</b> Scale is important to me because when I work large I feel as if I'm entering the world I'm creating. It's transporting. I'm not a wrist painter. I don't hold the brush the way I hold a pen if I'm writing a letter. I like to use my whole arm when I work. I like to stand, or put the canvas on the floor. Painting is very much a physical activity for me.</h4>
                <h4 className="individual-words-paragraph"><b>Art Interview: </b>What bridges the works that you have created in differing media?</h4>
                <h4 className="individual-words-paragraph"><b>Lorraine Shemesh:</b> The bridge between all of them is drawing. It is the manner in which my ideas first begin to germinate regardless of the medium that I eventually choose to use to develop the piece.</h4>
                
                <img className="individual-words-image-horizontal" src={'https://lorraineshemeshsiteimgs.s3.us-east-2.amazonaws.com/paper-int/lock_ld.jpg'} alt="Lock drawing" />
                
                <h4 className="individual-words-paragraph"><b>Art Interview: </b>Do you work on multiple pieces at a time?</h4>
                <h4 className="individual-words-paragraph"><b>Lorraine Shemesh:</b> Right now I have a large painting in the studio, but I've also been working on three-dimensional things with paper pulp. I've been cutting out images in paper. They don't have a figurative element in them, but there's patterning involved that's driven by the same impulse. l've been working with pulp since last summer. It's something I've never done before, and it's quite engaging. It takes color in a very interesting way, and the possibilities are endless. It's affected the new series of paintings that I'm working on. It's hard for me to be more specific than that.</h4>
                <h4 className="individual-words-paragraph"><b>Art Interview: </b>How did you come to work with the legendary art dealer, Allan Stone?</h4>
                <h4 className="individual-words-paragraph"><b>Lorraine Shemesh:</b> I knew Allan Stone's name long before I met him. For years, while I had been teaching at the Rhode Island School of Design, I'd been cutting out reproductions of things that I liked in magazines and pinning them to the walls of my studio. Often those reproduced images had a small footnote on the bottom that said "courtesy of the Allan Stone Gallery." After a few years of teaching I had a pretty large stack of paintings in my studio that I had been showing regionally in New England, when a friend suggested I take my work to Allan Stone. When I mentioned this to someone else, they laughed at me and said, "Are you crazy? You'll never get in to see him. He's the big-time." But, remembering all those images on my studio wall, I thought, someone must get in to see him. I figured it hurts just as much to get rejected in the big leagues as it does in the little leagues. Other artists that I knew had corroborated my friend's warning but also told stories that Allan was like no one else they'd ever heard about, certainly no other art dealer. I was told that he had an uncanny eye and that he flew in the face of conventional wisdom and took chances, in the same way that artists do, in choosing the work that he showed. Everyone who met him agreed that he was a straight shooter, but he was so busy that he often missed appointments.</h4>
                <h4 className="individual-words-paragraph">Whatever the deterrents were, this was sounding better and better to me. So I geared up, made a bunch of calls, and arranged to show my work to several dealers in the city, the last one being Allan Stone.</h4>
                <h4 className="individual-words-paragraph">I pounded the pavement for three days, carrying a painting with one hand and several sets of slides with the other. On the third day, a dealer with a gallery on 57th Street offered me a show of my own the following season, but it didn't feel like the right fit to me. He was surprised at my hesitation, and he said, "What are you waiting for? This is 57th Street." I said to him, "I have an appointment with Allan Stone tomorrow," and again I heard the old refrain, that I'd never get in to see him. Then under his breath, he said, "What's so great about Allan Stone, anyway?"</h4>
                
                <img className="individual-words-image-vertical" src={'https://lorraineshemeshsiteimgs.s3.us-east-2.amazonaws.com/paint-int/hook_8_colorCorrected_b.jpg'} alt="Zipper" />
                
                <h4 className="individual-words-paragraph">The next day, I packed up my painting and headed uptown with my eye on the dusty awning that jutted out into the street with Allan's name on it. Facing me was a long, dark stairwell, and when I got to the top, I just gasped because I'd never seen anything like this place. There were huge African sculptures, a giant Miro puppet, a semi-rolled-up stage backdrop, which when I looked closer I realized was painted by de Kooning. I forgot for a second why I was there, because there was so much to look at. There was a large desk, behind which sat a person named Joan Wolf, and the surrounding chairs were filled with a variety of people, several artists, a curator from the Met, another dealer, and someone from one of the auction houses. I took a seat in the only vacant chair available. People came and went, and I soon got the feeling that everyone had been there a very long time. But, somehow I didn't care. There was a buzz in the room, and anything seemed possible. I just loved this place. The atmosphere in the other galleries I'd been to was severe, stark, and minimal. There was a kind of serenity and reverence that these austere spaces induced. But, the feeling I got at Allan's was something very different. The emphasis of this gallery was on the juice that fuels the making of art. I sat there for six hours that first day, and sometime in the late afternoon, Joan told me that Allan was ready to see me.</h4>
                <h4 className="individual-words-paragraph">I picked up my work and walked into a room filled with so much stuff that I lost my bearings. I couldn't see him. There was a desk with four feet of indeterminate objects piled on top of it. From somewhere behind the wall of stuff came his voice. "Let me see your slides." "Where are you?" I said. "Behind the desk. Just throw them over." And so I did. I just stood there. I appreciated the quiet because genuine seeing is never done with one's mouth. And then he said, "Come to the other side of the desk." And I said, "How do I get there?" His response was, "Just follow the sound of my voice and put one foot in front of the other."</h4>
                <h4 className="individual-words-paragraph">I unwrapped the painting, and he looked at it for a while. As he looked, I couldn't help but be drawn to the amazing things that the room was filled with. Then, Allan began to talk about my painting. He went immediately to the area that I knew was the strongest part of the piece, and then to the one that was the weakest. He talked about Morandi and Balthus, two painters I greatly admired, and how they had worked. No other dealer that I had met had spoken at all about the process of making art. This was a very different sort of place, and I knew it immediately. I was also sure at that moment that this gallery was the one that I wanted to be a part of and that dealing with him would be very good for my work.</h4>
                
                <img className="individual-words-image-vertical" src={'https://lorraineshemeshsiteimgs.s3.us-east-2.amazonaws.com/quilts-objects3.jpg'} alt="Zipper" />
                
                <h4 className="individual-words-paragraph">Soon after that, I gave notice at my teaching job, packed up my studio, and against the better judgment of everyone I knew at the time, moved to New York City. Allan put me in a new talent show, and a year later gave me my first solo show. He decided to show my drawings and quilts along with the city-scape oil paintings I had been working on. I thought at the time that it was a risky thing to show the quilts with the other stuff. He dismissed my doubts, and shooed me away by saying, "Just go home and work, and leave the rest to me." He spoke to me very little before the show opened, or while it was up. It went well anyway.</h4>
                <h4 className="individual-words-paragraph">When I first met Allan, I was doing freelance advertising work in the city. I was staying up all night just to be able to do my own painting. When he agreed to give me a show, he said, "I want you to stop doing all the other work but your own work." And I said, "How can I do that? I have rent. How am I going to pay the rent?" And he said, "Do you trust me?" And I said, "I don't even know you! How can I trust you?" He said, "Just do your work. Do the work that you really want to do, and it will be okay." During the next six months, he bought a few of my pieces for his own collection, and then I had my first show and the show sold well. I managed to keep doing my own work, and never had to do freelance work again.</h4>
                <h4 className="individual-words-paragraph">When I first moved to the city, I was working in a costume factory. I did all kinds of things. I sewed. There were some things that I didn't want to do. I could draw, but I didn't want to do illustration because I feIt that was going to start to impede my work, So, I preferred doing the factory work, where I was making objects, making costumes, and doing those kinds of things. Allan was an extraordinary dealer, and he basically allowed me to make the work that I wanted to make.</h4>
                <h4 className="individual-words-paragraph">When I showed the first pool images he said to me, "This show is going to send you out into the world in a very different way." The show did do well, and then I came in and said to him, "You know, I really want to work with clay now," and he said, "Go ahead."</h4>
                
                <img className="individual-words-image-vertical" src={'https://lorraineshemeshsiteimgs.s3.us-east-2.amazonaws.com/clay-interview.jpg'} alt="Zipper" />
                
                <h4 className="individual-words-paragraph">I think the biggest difference between Allan and other dealers was his uncanny eye. He could really see. It has been said that, "a lot of dealers pick the work they show with their ears rather than with their eyes." Allan's highly honed visual instincts were legendary in the art world. He confidently and frequently went against the grain of the mainstream in choosing the work that he showed simply because he loved it. Also, the visual world has a specialized vocabulary and he could speak that language easily, because he had tried to paint when he was a young man. The bottom line for most dealers is the marketplace, what they think is and isn't going to sell. That was never what was most important to Allan and the artists knew that. So did the collectors, which was why both groups trusted him so implicitly. He cared most about the quality of the work that he showed. And, I also feIt free to explore whatever avenue seemed of interest to me.</h4>
                <h4 className="individual-words-paragraph"><b>Art Interview: </b>How old were you when you had your first show with him?</h4>
                <h4 className="individual-words-paragraph"><b>Lorraine Shemesh: </b>I was 33.</h4>
                <h4 className="individual-words-paragraph"><b>Art Interview: </b>Had you had experience with commercial galleries before then?</h4>
                <h4 className="individual-words-paragraph"><b>Lorraine Shemesh: </b>I'd had both a solo show and a few group shows at the Alpha Gallery in Boston. I had shown at the RISD Museum in Providence, and I had been in a group show at the Staempfli Gallery in NYC, but showing with Allan took things to another level.</h4>
                    
                <img className="individual-words-image-vertical" src={'https://lorraineshemeshsiteimgs.s3.us-east-2.amazonaws.com/paint-ls/arch.jpg'} alt="Zipper" />
                
                <h4 className="individual-words-paragraph">Allan was not interested in people coming up fast and going down fast. He didn't want anyone to be the flavor of the month. He was interested in people having long working lives. So, he chose his artists very carefully. He didn't ask for anybody's opinion or advice. I was fortunate. He encouraged me to do the work that I wanted to do, and the fact that it did well was just the bonus. The discussions that we had about art were so important and meaningful. Even then I realized how unusual it was for an artist to be having this kind of discussion with a dealer. We didn't discuss money or the marketplace. He never said to me, "Make three more of these in this size," or "Make them more blue." While I didn't see him often, when I did, his acute visual instincts always surprised me and sometimes shook me up.</h4>
                <h4 className="individual-words-paragraph"><b>Art Interview: </b>How did you create your pool paintings?</h4>
                <h4 className="individual-words-paragraph"><b>Lorraine Shemesh: </b>It started with a drawing. I sometimes sleep with a notepad next to my bed. Before I began the very first pool painting, I had a dream that I was floating on the water and there was something underneath me. I woke up in the middle of the night and scrawled the image in my head on the notepad. I then went into the studio and really developed that drawing. Next, I had some models come to the studio and drew some more. Then, I went into the water with the models, and did some photo work to reference the color shifts that occur on the flesh above and below the water line. I went back into the studio to do more drawings, and then I began the painting, and it just evolved from there.</h4>
                
                <img className="individual-words-image-vertical" src={'https://lorraineshemeshsiteimgs.s3.us-east-2.amazonaws.com/paint-pp/twistShout.jpg'} alt="Zipper" />
                
                <h4 className="individual-words-paragraph"><b>Art Interview: </b>Do you believe that the success and length of an artist's career is controlled by their dealer?</h4>
                <h4 className="individual-words-paragraph"><b>Lorraine Shemesh: </b>No. I believe that the success and length of a career is controlled by the quality and depth of the work made. An artist is only as good as the work that they're doing. It has less to do with the outside world and is more deeply determined by the quality of the work produced in the studio.</h4>
                <h4 className="individual-words-paragraph"><b>Art Interview: </b>How have your expectations of yourself changed over the years?</h4>
                <h4 className="individual-words-paragraph"><b>Lorraine Shemesh: </b>They've only gotten greater. ln your head it's so much more than it winds up being. Someone once said to me, "How do you know when it's finished?" And I said, "It's never finished!" There's a point in every piece when you discover that something can't be resolved in that particular piece. And that's what makes you make a new one. The impulse is to try to get it to meet the expectation in your head. Therefore, the bar is always raised higher. It spurs you on. I sometimes wonder why I'm still so interested in this after all this time. I'm as engaged as I was when I first squeezed the paint out of the tubes and put it onto the canvas when I was ten. The thrill of that is still very prevalent. Sometimes I see someone making something in a way I haven't thought of, and that's exciting. There are so many things that I am stimulated by.</h4>
                
                <img className="individual-words-image-vertical" src={'https://lorraineshemeshsiteimgs.s3.us-east-2.amazonaws.com/pools-interview.jpg'} alt="Zipper" />                
                <h4 className="individual-words-paragraph"><b>Art Interview: </b>And what do you feel has been your greatest success to date?</h4>
                <h4 className="individual-words-paragraph"><b>Lorraine Shemesh: </b>That I still get to do it. That's the brass ring I think I've grabbed. I get to create work all day long. l've been incredibly fortunate, to have known, from an early age, what I wanted to do with my life. When I was a child, my dream was to live in a house where I had made everything in it. And you know, I've gotten to do that; I have made our furniture, the plates, the quilts, the paintings. I painted my way through school, and I've painted my way through life. I like the way I spend my days. Liking the way you spend your days and with whom - I think those are the two things that are key. I guess the simple thing about receiving an education is, that everyone you come across poses questions that you, hopefully, spend the rest of your life answering for yourself. I think the work that I've made is my witness. It witnesses the world in which I've lived and the experiences that I've had, and that's a powerful thing. It's constantly engaging. The work has brought me to places I never would have imagined going to.</h4>
                <h4 className="individual-words-paragraph"><b>Art Interview: </b>What are your plans for the future?</h4>
                <h4 className="individual-words-paragraph"><b>Lorraine Shemesh: </b>Well, the work that I'm doing now, this latest series, involves my early interest in dance and my new interest in paper pulp. That's all I'm going to say about it, but it seems like it's bringing together a lot of the things that I've always really been drawn to. I'm hoping that it will continue to surprise me.</h4>
                <div className="individual-words-text-container">
                    <p className="individual-words-closing-text">This oral history transcript is the result of a digitally recorded interview with Lorraine Shemesh on 30 April, 2010. The interview took place over the telephone between Berlin, Germany and New York, New York, USA. Brendan Davis conducted this interview for Art Interview Online Magazine. Cara Cotner wrote the introductory text at the beginning of this interview.</p>
                </div>
            </div>
        </div>
    )
}

export default Biblio_davis;