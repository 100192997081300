import React, { useEffect } from "react";

const Biblio_cheng = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    });
    
    return (
        <div>
            <div className="individual-words-hero-image-container">
                <img className="individual-words-hero-image-horizontal" src={'https://lorraineshemeshsiteimgs.s3.us-east-2.amazonaws.com/paint-int/lock_03_colorCorrected.jpg'} alt="Lorraine in studio shot"/>
            </div>
            <div className="individual-words-paragraph-container">
                <h4 className="individual-words-title"><b>Q & A - INTERSECTIONS</b></h4>
                <h4 className="individual-words-paragraph"><i>SC: In the past you've concentrated on paintings of people floating and swimming in water, which reflected a fascination for the fragmentation of natural light on and around them. What is the genesis or inspiration for this new series of work, which is more evidently staged and choreographed?</i></h4>
                <h4 className="individual-words-paragraph">LS: The idea was actually conceived for a dance company as a performance piece but as I developed the concept it became something else entirely. As the figures moved together, something strange began to happen visually. Since their bodies were both completely covered, there was no separation between them. They began to synthesize into one larger form. In one painting the figures share a limb. The juxtaposition of the arms and legs and the black and white patterns created unexpected intersections and unions which I pushed and built on. The result is a series of imagined forms, born in reality, but then transformed. The hood—which I designed as part of the costume to conceal facial expressions—allowed the pattern of the unitard to continue throughout the entire form, heightening the emphasis on the body language created between the two figures when they moved together.</h4>
                <h4 className="individual-words-paragraph"><i>SC: How did you go about actualizing your vision. What references did you draw upon?</i></h4>
                <h4 className="individual-words-paragraph">LS: My long standing interest in African textiles, especially Kuba cloths, played a part in the development of this work. These appliquéd and embroidered fabrics favor an improvisational, fluid effect that plays with deliberate asymmetries and pattern variation. The ones I am most strongly drawn to have the simplest palette, a clear differentiation of light and dark fabric. The designs are subtle and unpredictable. They also serve as functional pieces of clothing when wrapped around the body. My attraction to pattern on form, led me to design a unitard with wide geometric bands on it. I also referenced the weight-sharing approach to partnering developed by the dance company, Pilobolus, along with the inventiveness and physical beauty of the work performed by Momix. Objects like bicycle gears, wheels, and zippers can also be cited as inspirational source material.</h4>
                <h4 className="individual-words-paragraph"><i>SC: To what degree did the dancers you selected help create the composition of these works?</i></h4>
                <h4 className="individual-words-paragraph">LS: The initial black and white ink drawings I had developed were pinned to the wall behind the dancers in the studio, as a reference and point of departure. These small studies, indicated gestures in which the figures were interlocked and joined in a variety of ways. The models saw them, certainly, but I believed that it would be limiting to try and imitate them. Instead, I put on a cd track in the studio and asked the models to respond to the music by slowly moving from one of the gestures of the ink images to the next. What happened in between became the thing of interest to me. Even as a particular action wound down, I noticed a movement in the stillness that was visually arresting.</h4>
                <h4 className="individual-words-paragraph"><i>SC: Why did you decide on a limited palette?</i></h4>
                <h4 className="individual-words-paragraph">LS: The choice changed the visual and emotional tenor of the work. I don't think using a black and white palette is "limited," however. On the contrary, it presents a limitless set of possibilities. While the color is restrained, it is there, nonetheless. The beauty of paint is the subtle range of color and feeling to be found in and between white and black. Visually, the graphic possibilities of working with black and white also appealed to me. Initially, I thought that in a dark theater the black of the costume would bleed into the surrounding space. The resulting experience would be a visually abstract one. The silhouettes of the figures, (the outer contours of their bodies), would be lost. But as the figures moved together, their actions would make the white blocks, when lit with a spot, move rapidly, undulating, like flashes of bright light. I envisioned creating a series of "ZIPPERS," locking together and pulling apart in fluid and surprising ways. As I began the paintings, however, the drama of the darker figure silhouettes projecting from a lighter ground and a shallow space became more riveting. In some instances, the negative and positive shapes flip back and forth in surprising ways. Emotionally, using black and white, also provided the opportunity to mine different, and in some instances, darker territory.</h4>
                <h4 className="individual-words-paragraph"><i>SC: In your previous work, you fill the entire picture plane, but often cut off the figures—such as the lower half, torsos and legs. In the new series, the figures are depicted in full length and totally contained within the picture plane. What effect do you want to convey with this use of space?</i></h4>
                <h4 className="individual-words-paragraph">LS: The relationship of the size and shape of the canvases in this series is very deliberate. As the two figures locked together into their new form there was a magnetic quality created between them that is heightened by the edge tension that occurs as the forms play off of the perimeter of the canvas. The size and shape of the frame serve to contain the forms and allow them to push and pull against it in a variety of ways. I was interested in the dialogue that occurs between the two. The stark contrast between the forms of the figures and the emptiness of the space in which they were situated was evocative of many things for me. The resulting images work in a group and form a kind of frieze when viewed together.</h4>
                <h4 className="individual-words-paragraph"><i>SC: As you know, many contemporary artists have moved to abstract and conceptual work. Why do you continue to do figural work?</i></h4>
                <h4 className="individual-words-paragraph">LS: Working with the figure is charged and loaded on so many levels. Physically, psychologically, and, certainly, visually. It is a challenging subject, especially in the face of Modernism. Working with the figure can also embrace the tenets of Modernism embodying universal principles which are both vital and timeless. I am interested in a kind of specificity that precludes illustration and transcends photorealism both in content and painterly acumen. When I work, I look for a way to use the figure that reaches beyond academic strictures and constraints, to express the anxious time in which we live, where machines and humanity while co-dependent, are often at odds. For example, the zipper is used as a formal mechanism and as a metaphor, because it has the capacity to hold things together, and to open and close them, in visual, as well as psychological terms.</h4>
                <h4 className="individual-words-paragraph"><i>SC: Do you feel there is some personal turning point you're addressing in this work?</i></h4>
                <h4 className="individual-words-paragraph">LS: Life is so ephemeral. Dance reflects the fleeting nature of the human experience, as does the idealized figure. The question for me was how to hold onto the temporal experience in a lasting way. And my desire "to hold on," at this particular point in time, is probably linked to the process of living with grief. There is truth in the knowledge that when you lose someone you love, the work gets pulled in that direction. That is not an act of will. It just happens. The events of our lives reveal themselves thematically in the work that we do, not on the surface, perhaps, but in the deeper fabric of the things that we make afterwards. The work has a life of its own, and in creating it, we return to ourselves altered, but, once again, whole.</h4>
                <div className="individual-words-text-container">
                    <p className="individual-words-closing-text">Questions: Scarlet Cheng - Arts Writer, regular contributor to The Los Angeles Times, Artillery, THE magazine, and other publications.</p>
                    <p className="individual-words-closing-text">Answers: Lorraine Shemesh - Painter</p>
                </div>
            </div>
        </div>
    )
}

export default Biblio_cheng;
;