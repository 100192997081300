import React, { useEffect } from "react";
import { NavLink } from 'react-router-dom';

const Biblio_purcell = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    });
    
    return (
        <div>
            <div className="individual-words-hero-image-container">
                <img className="individual-words-hero-image-vertical" src={'https://lorraineshemeshsiteimgs.s3.us-east-2.amazonaws.com/paper-p/totem_gwd.jpg'} alt="Totem drawing, graphite wash on mylar"/>
            </div>
            <div className="individual-words-paragraph-container">
                <h4 className="individual-words-title"><b>FINE ARTS: MUST-SEE EXHIBIT IN PRINCETON</b></h4>
                <h4 className="individual-words-paragraph">Once again the Arts Council of Princeton is fulfilling its goal to present quality art for both enjoyment and education. The current exhibition, "Philip Pearlstein - A Legacy of Influence" explores the painter of modernist realism's far-reaching effect on eight artists who were his students, including Charles David Viera, who curated the exhibit.</h4>
                <h4 className="individual-words-paragraph">Viera says in his catalog essay that accompanies the show, "This exhibition is about that ascendancy." He goes on to speak of Pearlstein's artwork that was "justifiably considered a true contemporary reinvention of the image of the human figure."</h4>
                <h4 className="individual-words-paragraph">In Pearlstein's paintings on display of models in chrome chairs, the models are peaceful, perhaps even dozing, while surrounded by busy and bright textiles, and even a red airplane "kiddie car," all of which are reflected in the shiny chrome of the chair--along with a face here, a foot or a leg there. In these, as well as his lithograph on display, "Two Nudes with Adirondack Rocker, he relieves the images from associations of eroticism or romanticism and presents them with all their irregularities and natural distortions exactly the way he sees them.</h4>
                <h4 className="individual-words-paragraph">"What has stuck with me was his insistence that we take a hard and rigorous look at the world that is in front of our eyes," says artist Janet Fish in the exhibition catalog. In her large oil painting, "Green Cloth Pink Cloth" she precisely rendered various glass dishes and bowls that rest in their reflections in the reflected light and shadows falling on the iridescent cloths. It is clear this is exactly what was before her eyes as she painted.</h4>
                <h4 className="individual-words-paragraph">Altoon Sultan speaks in the catalog of Pearlstein's broad tastes in art and of being told by him "no matter what you choose to do with art, go deeply into it and push the idea as far as you can." Sultan did exactly that in his small egg tempera works, "Hidden Bolt" and "Orange and Black" in which he focuses on geometric segments of his subject pushing and successfully conveying his vision of their flat and uncomplicated simplicity.</h4>
                <h4 className="individual-words-paragraph">Lorraine Shemesh, on the other hand, followed Pearlstein's encouragement to "step outside the box by using the figure as a way of composing a picture plane in a very abstract way." Working with graphite wash on Mylar, she created two arresting works, one of which is <NavLink to="/paperPools/paperpool6" className="words-link" target="wordsImage">Totem</NavLink> where black and white shapes and almost indiscernible faces seem to float and fly up and away carried on a fluid flow of grey.</h4>
                <h4 className="individual-words-paragraph">Tony Phillips also adds mystery to his images, "Marquis of Hearts" and "Humble Supplicant," while pushing to convey his idea of the way he wants "people and the things of the world" to appear in his work. The figures in his oil "Humble Supplicant" and his larger pastel, "Marquis of Hearts" are still and seen only in profile. The anthropomorphic "Humble Supplicant" kneels in jeans and work shoes, his upper animal body, eyes closed, long ears drooping, faces the sea and the setting--or rising--sun.</h4>
                <h4 className="individual-words-paragraph">And there's an unsettled feeling of mystery in Stephen Lorber's pigment print, "Lunch at the Blue Ben, Bennington, Vermont." You feel something is about to happen in the near-empty diner. Waitresses chat, the few patrons are unaware and a young woman in a plaid shirt, straw hat and a handgun tucked in the back pocket of her jeans stands--watching?--perusing the posted specials? Is her right hand resting innocently on her hip or is it about to reach back to that handgun? Lorber was influenced by Pearlstein during his years at Brooklyn College and says "His support then and in the years after was of incalculable importance to me."</h4>
                <h4 className="individual-words-paragraph">Tom Corey, also from Pearlstein's Brooklyn College days, says "Being with Philip on our excursions around Europe has been the best education in art that one could receive." Corey's pastel painting "The Terrace" suggests ethereal white chaise lounges, that seem to be suspended over strong reflections of the blue narrow-post fence that holds them apart from a green landscape beyond.</h4>
                <h4 className="individual-words-paragraph">Pearlstein inspired George Nick when he taught at Yale. In his oil painting "Prometheus Unbound 30" Nick conveys a moment in time by flooding a street scene with bright light glinting on windows, glancing off building facades and landing on sidewalks and steps. Strong shadows break up the light into strong patterns bringing an otherwise empty scene to life.</h4>
                <h4 className="individual-words-paragraph">And celebrating the liveliness of this exciting exhibition are curator Charles David Viera's acrylic paintings, "Big Blue Jay" and "Big Red Cardinal." Although the temptation is to get right up close to inspect the mosaic-like segments of color that underlay the spontaneous outline of the birds, first move to the far end of the gallery and view their energy from that vantage point. They are dynamic and the orange highlights on their claws will grab you and pull you back to a close-up enjoyment.</h4>
                <h4 className="individual-words-paragraph">In his curator essay, Viera speaks of Pearlstein's active contribution to the international art scene for over 70 years and says, "The artists in this exhibition have had the opportunity to know him on a more personal level and I speak for all of them when I say that his full legacy of influence has yet to be felt." Once again the Arts Council of Princeton is fulfilling its goal to present quality art for both enjoyment and education. The current exhibition, "Philip Pearlstein - A Legacy of Influence" explores the painter of modernist realism's far-reaching effect.</h4>
                <div className="individual-words-text-container">
                    <p className="individual-words-closing-text">Janet Purcell, The Times of Trenton review</p>
                </div>                
            </div>
        </div>
    )
}

export default Biblio_purcell;